*{
    padding:0;
    margin:0;
    font-size: 5vw;
    /*background-repeat: no-repeat;*/
}
body{
    background: #ffffff;
}
/*logo开始*/
.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:9vh;
    margin-bottom: 8vh;
}
.logo img{
    border-radius: 50%;
    width:65vw
}

.logo p{
    font-weight: 100;
    letter-spacing: 4vw;
    text-align: center;
    padding-left:5vw;
    position: relative;
    top:-2.5vh;

}
/*登录开始*/
.log{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logItem,.log a{
    margin-bottom: 3vh;
}
.logItem p{
    color:#B5B5B5;
}
.logItem input{
    border-width:0 0 2px 0;
    border-bottom-color:#222222;
    background:transparent;
    text-align: left;
    width:65vw;
}
.logItem input::placeholder{
    font-size: 3.5vw;
}
.logItem input:focus{
    outline: none;
}
.logButton{
    width:65vw;
    letter-spacing: 1vw;
    text-align: center;
    font-size:6vw;
    box-sizing: border-box;
    padding:2vw ;
    background:#34496B;
    color:#ffffff;
    margin-top: 2vh;
    border: none;
}
.logButton:hover{
    cursor: pointer;
}
.log .info {
    margin-top:4vh;
}
.log .info span{
    color:#999999;
    font-size: 4vw;
    margin:0 6vw;
}